/* eslint-disable sonarjs/no-duplicate-string */
export const VitalSignsLanguageKeys = {
  VITAL_SIGNS: {
    AMOUNT_LMIN_TEXTBOX_LABEL: 'Amount L/min',
    AMOUNT_PER_TEXTBOX_LABEL: 'Amount %',
    AUSCULTATION: 'Auscultation',
    AVPU_ASSESSMENT: 'AVPU Assessment',
    BLOOD_PRESSURE: 'Blood Pressure',
    BREATHING: 'Breathing',
    CELSIUS: 'Celsius',
    CHART_INPUTS: 'Chart Inputs',
    CIRCULATION: 'Circulation',
    COMMENTS: 'Comments',
    DELIVERY_METHOD: 'Delivery Method',
    DEPTH: 'Depth',
    DIASTOLIC: 'Diastolic',
    DISABILITY: 'Disability',
    EQUALITY: 'Equality',
    EXPOSURE: 'Exposure',
    FAHRENHEIT: 'Fahrenheit',
    GRAPH_WIDTH: 'GRAPH_WIDTH',
    LEFT_LUNG: 'Left Lung',
    LEVEL_OF_RESPONSE: 'Level of Response',
    MODE: 'Mode',
    NOTES: 'Notes',
    ON_MOVEMENT: 'On movement',
    OTHER: 'Other',
    OXYGEN_DELIVERY: 'Oxygen Delivery',
    OXYGENATION: 'Oxygenation',
    PAIN_SCORE: 'Pain Score',
    PATTERN: 'Pattern',
    POSITION: 'Position',
    PULSE_TEXTBOX_LABEL: 'Pulse label',
    PULSE: 'Pulse',
    RESPIRATION: 'Respiration',
    REST: 'Rest',
    RHYTHM: 'Rhythm',
    RIGHT_LUNG: 'Right Lung',
    SATURATION_TEXTBOX_LABEL: 'Saturation %',
    SITE: 'Site',
    STRENGTH: 'Strength',
    SYSTOLIC: 'Systolic',
    TEMPERATURE_AXIS_LABEL: 'Temperature (F)',
    TEMPERATURE_UNIT: 'F',
    TEMPERATURE_VALUE: '{temperatureFahrenheit}',
    TEMPERATURE: 'Temperature',
    VITAL_SIGNS_AIRWAY: 'Airway',
    VITAL_SIGNS: 'Vital Signs',
    SCORE: 'Score',
    TOTAL_SCORE_TEMPLATE: 'Total Score: {totalScore}',
    NURSING_ACTIONS: 'Nursing Actions',
    ADDS_VERBOSE: 'Adult Deterioration Detection System (ADDS)',
    ADDS_SCORING_ACTIONS: {
      FREQUENCY: 'Frequency',
      EMERGENCY_ALL_IF_WITH_COLON: 'Emergency call if:',
      INFORM_SENIOR_NURSE_AND_OR_TEAM_LEADER: 'Inform senior nurse and/or Team Leader',
      INCREASE_FREQUENCY_OF_OBSERVATIONS_SPECIFY_FREQUENCY: 'Increase frequency of observations [specify frequency]',
      SENIOR_NURSE_AND_OR_JUNIOR_MEDICAL_OFFICER_REVIEW_WITHIN_30_MINS: 'Senior nurse and/or junior medical officer review within 30 minutes',
      SENIOR_MEDICAL_OFFICER_REVIEW_REGISTRAR_OR_ABOVE_WITHIN_30_MINS: 'Senior medical officer review (registrar or above) within 30 minutes',
      REQUEST_REVIEW_AND_NOTE_ON_THE_BACK_OF_THIS_FORM: 'Request review, and note below',
      PLACE_EMERGENCY_CALL: 'Place Emergency call',
      BEGIN_INITIAL_LIFE_SUPPORT_INTERVENTIONS_SUPPORTS_AIRWAY_BREATHING_CIRCULATION: 'Begin initial life support interventions (support airway, breathing, circulation)',
      ADVANCED_LIFE_SUPPORT_PROVIDER_TO_ATTEND_PATIENT_IMMEDIATELY: 'Advanced life support provider to attend patient immediately',
      ANY_OBSERVATION_ID_IN_A_PURPLE_AREA: 'Any observation is in a purple area',
      AIRWAY_THREAT: 'Airway threat',
      RESPIRATORY_OR_CARDIAC_ARREST: 'Respiratory or cardiac arrest',
      NEW_DROP_IN_02_SATURATION_LESS_THAN_90_PERCENT: 'New drop in O₂ saturation < 90%',
      SUDDEN_FALL_IN_LEVEL_OF_CONSCIOUSNESS: 'Sudden fall in level of consciousness',
      SEIZURE: 'Seizure',
      YOU_ARE_SERIOUSLY_WORRIED_ABOUT_THE_PATIENT_BUT_THEY_DO_NOT_FIT_THE_ABOVE_CRITERIA: 'You are seriously worried about the patient but they do not fit the above criteria'
    },
    GRAPH_NAME: {
      RESPIRATION: 'VITAL_SIGN_CHART_GRAPH_RESPIRATION',
      OXYGENATION: 'VITAL_SIGN_CHART_GRAPH_OXYGENATION',
      BLOOD_PRESSURE: 'VITAL_SIGN_CHART_GRAPH_BLOOD_PRESSURE',
      PULSE: 'VITAL_SIGN_CHART_GRAPH_PULSE',
      TEMPERATURE: 'VITAL_SIGN_GRAPH_CHART_TEMPERATURE'
    }
  }
};

// Since the react-intl take can take the id as fallback value,
// so we just need part of these keys in the overriding values.
export type VitalSignsMessageType = Partial<Record<keyof typeof VitalSignsLanguageKeys, string>>;

// This is the example of usage. Remove if not needed.
export const VitalSignsEnUs: VitalSignsMessageType = {
  [VitalSignsLanguageKeys.VITAL_SIGNS.CHART_INPUTS]: 'Chart Input',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_WIDTH]: 600,
  [VitalSignsLanguageKeys.VITAL_SIGNS.PULSE_TEXTBOX_LABEL]: 'Pulse',
  [VitalSignsLanguageKeys.VITAL_SIGNS.TEMPERATURE_AXIS_LABEL]: 'Temperature (F)',
  [VitalSignsLanguageKeys.VITAL_SIGNS.TEMPERATURE_UNIT]: 'F',
  [VitalSignsLanguageKeys.VITAL_SIGNS.TEMPERATURE_VALUE]: '{temperatureFahrenheit}',
  [VitalSignsLanguageKeys.VITAL_SIGNS.VITAL_SIGNS]: 'Vital Signs',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.BLOOD_PRESSURE]: 'Blood Pressure',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.OXYGENATION]: 'Oxygenation',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.PULSE]: 'Pulse',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.RESPIRATION]: 'Respiration',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.TEMPERATURE]: 'Temperature'
};

export const VitalSignsEnAu: VitalSignsMessageType = {
  [VitalSignsLanguageKeys.VITAL_SIGNS.CHART_INPUTS]: 'Current Inputs/Observations',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_WIDTH]: '91%',
  [VitalSignsLanguageKeys.VITAL_SIGNS.PULSE_TEXTBOX_LABEL]: 'Beats/minute',
  [VitalSignsLanguageKeys.VITAL_SIGNS.TEMPERATURE_AXIS_LABEL]: 'Temperature (C)',
  [VitalSignsLanguageKeys.VITAL_SIGNS.TEMPERATURE_UNIT]: 'C',
  [VitalSignsLanguageKeys.VITAL_SIGNS.TEMPERATURE_VALUE]: '{temperatureCelsius}',
  [VitalSignsLanguageKeys.VITAL_SIGNS.VITAL_SIGNS]: 'Vital Signs / A to E Assessment',
  [VitalSignsLanguageKeys.VITAL_SIGNS.TOTAL_SCORE_TEMPLATE]: 'Total Score: {totalScore}',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.BLOOD_PRESSURE]: 'Blood Pressure',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.OXYGENATION]: 'Saturation',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.PULSE]: 'Pulse',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.RESPIRATION]: 'Respiration',
  [VitalSignsLanguageKeys.VITAL_SIGNS.GRAPH_NAME.TEMPERATURE]: 'Temperature'
};
