import moment, { Moment } from 'moment';
import { DATE_FORMAT, DATE_TIME_AM_PM_FORMAT, DATE_TIME_FORMAT, DateFormatByLocale, DateTimeFormatByLocale, Locales, TIME_FORMAT } from 'constants/app.constant';

export const getDateFormatByLocale = (locale: Locales = Locales.EN_US) => {
  return DateFormatByLocale[locale];
};

export const getDateTimeFormatByLocale = (locale: Locales = Locales.EN_US) => {
  return DateTimeFormatByLocale[locale];
};

export const isDateCorrectFormat = (date: string) => moment(date, DATE_FORMAT, true).isValid();

/**
 * Check if the date is valid with the given format.
 * @param date
 * @param format
 * @returns {boolean}
 */
export const isValidDateFormat = (date: string, format: string | string[]): boolean => moment(date, format, true).isValid();

export const datePickerFormatter = (str: string) => moment(str).format(DATE_FORMAT);

/**
 * This format comes from DatePickerInput component,
 * and modified by els-form-field-react.DateComponent (https://github.com/elsevierPTG/els-component-form-field-react/blob/develop/module/components/date/DateComponent.js#L91),
 * and the moment string format is different from this modified format (https://momentjs.com/docs/#/parsing/string-format/).
 * So we need to convert this format to use with moment.
 * @param format
 * @returns {string}
 */
export const convertELSDateFormat = (format: string): string => {
  return format.toUpperCase();
};

export const datePickerFormatterWithCustomFormat = (date: Date, format: string): string => {
  const dateFormat = convertELSDateFormat(format);
  return moment(date).format(dateFormat);
};

export const datePickerParser = (str: string) => {
  const valid = isDateCorrectFormat(str);
  return valid ? moment(str, DATE_FORMAT, true).toDate() : undefined;
};

export const datePickerParserWithCustomFormat = (date: string, format: string): Date => {
  const dateFormat = convertELSDateFormat(format);
  const isValid = isValidDateFormat(date, dateFormat);
  const parsedDate = moment(date, dateFormat).toDate();
  return isValid ? parsedDate : undefined;
};

export const toMoment = (date: string): Moment => (moment(date).isValid() ? moment(date) : moment(date.replace(/-/g, '/')));

export const toMomentWithFormat = (date: string, format: string | string[]): Moment => {
  if (moment(date, format).isValid()) {
    return moment(date, format);
  }
  return moment(date ? date.replace(/-/g, '/') : date, format);
};

/**
 * Converts a string date to a Moment object using a list of possible date formats.
 * If the date is in ISO string format, it will be converted to a Moment object as well.
 * This function ensures that the date is in the correct format (US, AU, and ISO format).
 * @param date - The string date to be converted to a Moment object.
 * @returns A Moment object representing the input date.
 */
export const toMomentWithParsers = (date: string): Moment => {
  const parsers = [...Object.values(DateTimeFormatByLocale), ...Object.values(DateFormatByLocale)];
  const isValidFormat = isValidDateFormat(date, parsers);

  if (isValidFormat) {
    return moment(date, parsers);
  }

  // If the date is ISO string
  return moment(date);
};

export const toMomentDateTime = (dateTime: string) => toMomentWithFormat(dateTime, DATE_TIME_FORMAT);

export const toMomentDate = (date: string) => toMomentWithFormat(date, DATE_FORMAT);

export const toISO = (datetime: string, locale?: Locales) =>
  locale ? toMomentWithFormat(datetime, getDateFormatByLocale(locale)).toISOString() : toMoment(datetime).toISOString();

export const isFutureDate = (date: Date): boolean => {
  return moment(date, Object.values(DateFormatByLocale)).isAfter(moment());
};

export const isPastDate = (date: Date, limit = { year: 1899, month: 11, date: 31 }): boolean => {
  return moment(date, Object.values(DateFormatByLocale)).isBefore(moment(limit)); // before 1900
};

export const toDateTimeAmPm = (dateTimeIso: string, defaultIfEmpty = ''): string => (dateTimeIso ? toMoment(dateTimeIso).format(DATE_TIME_AM_PM_FORMAT) : defaultIfEmpty);

export const toDateTime = (dateTimeIso: string, defaultIfEmpty = ''): string => (dateTimeIso ? toMoment(dateTimeIso).format(DATE_TIME_FORMAT) : defaultIfEmpty);

/**
 * TODO: Refactor to make the format can be passed as a parameter.
 * @param dateTimeIso
 * @param defaultIfEmpty
 * @param checkFormat
 */
export const toDate = (dateTimeIso: string, defaultIfEmpty = '', checkFormat = false): string => {
  if (checkFormat) return isDateCorrectFormat(dateTimeIso) ? toMoment(dateTimeIso).format(DATE_FORMAT) : defaultIfEmpty;
  return dateTimeIso ? toMoment(dateTimeIso).format(DATE_FORMAT) : defaultIfEmpty;
};

/**
 * Format date, EN_US by default.
 *
 * @param checkFormat - default true
 * @param date - DateTimeISO
 * @param defaultIfEmpty
 * @param customFormat
 * @param locale - Locales
 * @returns {string}
 */
export const formatDate = ({
  checkFormat = true,
  date,
  defaultIfEmpty = '',
  customFormat,
  locale = Locales.EN_US,
  includeTime = false
}: {
  checkFormat?: boolean;
  date: string | Date;
  defaultIfEmpty?: string;
  customFormat?: string;
  locale?: Locales;
  includeTime?: boolean;
}): string => {
  if (!date) return defaultIfEmpty;

  const format = customFormat || (includeTime ? getDateTimeFormatByLocale(locale) : getDateFormatByLocale(locale));

  if (checkFormat && typeof date === 'string' && !isValidDateFormat(date, format)) {
    return defaultIfEmpty;
  }

  if (typeof date === 'string') {
    return toMomentWithFormat(date, format).format(format);
  }

  if (!toMoment(date.toString()).isValid()) {
    return toMoment(defaultIfEmpty).format(format);
  }

  return moment(date).format(format);
};

export const toTime = (time: string, defaultIfEmpty = ''): string => (time ? toMoment(time).format(TIME_FORMAT) : defaultIfEmpty);

export const now = (format = DATE_TIME_FORMAT) => moment().format(format);

export const nowDate = (format = DATE_FORMAT) => moment().format(format);

/**
 * Calculates the offset in months between a given date and the current date.
 *
 * @param {string} dateString - The date to calculate the offset from
 * @param {Locales} locale - The locale used to determine the format of the date string.
 * @param {Moment} dateToCompare - The date to compare against. Defaults to the current date.
 * @return {number} The offset in months between the given date and the current date. Returns 0 if the date string is not valid.
 */
export const getDateOffset = (dateString: string, locale: Locales, dateToCompare = moment()) => {
  const format = getDateFormatByLocale(locale);
  const valid = moment(dateString, format, true).isValid();
  if (!valid) {
    return 0;
  }
  const date = moment(dateString, format, true);
  return date.diff(dateToCompare, 'months') * -1;
};

/**
 * Generates a new date of birth by adding a specified number of months to a given date string.
 *
 * @param {string} dateString - The original date string
 * @param {number} dateOffset - The number of months to add to the original date.
 * @param {Locales} locale - The locale used to determine the date format.
 * @return {string} The new date of birth
 */
export const getNewDateOfBirth = (dateString: string, dateOffset: number, locale: Locales) => {
  const format = getDateFormatByLocale(locale);
  const date = moment(dateString, format, true);
  const newDate = date.add(dateOffset, 'month');
  return newDate.format(format);
};

/**
 * Get the age from a given date.
 * @param object: { date: string; locale: Locales }
 * @returns number
 *
 * Previous implementation note in app.helper > dateToAge (updated date format):
 * age > 2 years -> Age: X years
 * age > 1 year and <= 2 years -> Age: X months
 * age > 1 month and <= 1 year -> Age: X months Y days
 * age <= 1 month -> Age: X days
 * @param dateString MM-DD-YYYY or DD/MM/YYYY string
 * @param locale current locale
 */
export const dateToAgeString = ({ date, locale }: { date: string; locale: Locales }): string => {
  const format = getDateFormatByLocale(locale);
  const isValid = isValidDateFormat(date, format);

  if (!isValid) return '';

  const years = moment().diff(moment(date, format), 'years');

  if (years >= 2) {
    return `${years} years`;
  }

  const months = moment().diff(moment(date, format), 'months');
  const monthUnit = months > 1 ? 'months' : 'month';

  if (months > 12) {
    return `${months} months`;
  }

  const days = moment().subtract(months, 'months').diff(moment(date, format), 'days');
  const dayUnit = days > 1 ? 'days' : 'day';

  if (days === 0) {
    return `${months} ${monthUnit}`;
  }

  if (months > 0) {
    return `${months} ${monthUnit} ${days} ${dayUnit}`;
  }

  return `${days} ${dayUnit}`;
};

/**
 * Add days with the given amount to the given date.
 * Supports for formats in DateFormatByLocale.
 * @param date - string (in DateFormatByLocale)
 * @param amount - number
 * @returns Moment
 */
export const addDays = (date: string, amount: number): Moment => {
  const isValidDate = Object.values(DateFormatByLocale).map((format) => isValidDateFormat(date, format));
  if (!isValidDate.includes(true)) return undefined;
  return moment(date, Object.values(DateFormatByLocale)).clone().add(amount, 'day');
};
